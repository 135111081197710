<template>
  <div class="meetings">
        <div class="row page_header_all">
      <div class="col-lg-6 col-12 right_content">
        <div class="page_title">
          <div class="title_icon">
            <img src="/media/svg/plus.svg" />
          </div>
          <h6 class="title">قائمة الإجتماعات</h6>
        </div>
        <div class="page_input_search position-relative">
          <input
               
            @input="getMeetings"
            v-model="search_word"
            class="name_search"
            placeholder="بحث بالاسم"
          />
          <button class="search-icon">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div  class="col-lg-4 col-12 Centering mr_small">
           <div class="checkbox-items">
          <div class="current-mettings d-flex">
            <vs-checkbox
              color="success"
              @change="getfiltermeeting('inprogress_meeting')"
              v-model="inprogress_meeting"
            >
              الإجتماعات الحالية
            </vs-checkbox>
            <!-- <vs-checkbox
              color="success"
              @change="getfiltermeeting('pending_meetings')"
              v-model="pending_meetings"
            >
              الإجتماعات القادمة
            </vs-checkbox> -->
            <vs-checkbox
              color="success"
              @change="getfiltermeeting('complete_meetings')"
              v-model="complete_meetings"
            >
              الاجتماعات السابقة
            </vs-checkbox>
          </div>
        </div>
      </div>

      <div class="col-lg-2 action_buttons mr_small">
        <button
          class="btn_Green btn_main m-0"
         v-if="!userData.roles.includes('viewer')"
          @click="$router.push('/new_meeting')"
         
        >
              <i class="fas fa-plus"></i>
                    <span>
             
            إجتماع جديد
          
          </span>
        </button>
      </div>
    </div>
 
    <div class="meeting">
      <div class="row">
        <div
          v-for="meeting in meetingsList"
          :key="meeting.id"
          class=" col-xxl-3  col-xl-3  col-lg-4 col-md-4 col-sm-12"
        >
          <div class="meeting-container">
            <div class="meeting-title d-flex justify-content-between">
              <h4 class=" ">
                <a @click="$router.push(`view_meeting/${meeting.slug}`)">
                  <truncate
                    clamp="..."
                    :length="40"
                    less="Show Less"
                    :text="meeting.title"
                  ></truncate>
                </a>
              </h4>
              <a
                title="الدخول للإجتماع"
                class="start_meeting_link"
                @click="$router.push(`/mt/${meeting.slug}`)"
              >
              <i class="fas fa-sign-in-alt"></i>
              </a>
            </div>
            <div class="meeting-content d-block">
              <div class="time">
               <i class="icon_img far fa-clock"></i>
                <span class="meeting-time">
                  <span >
                    {{ meeting.start_time | moment("h:mm a") }}
                  </span>
                  -
                  <span >
                    {{ meeting.end_time | moment("h:mm a") }}
                  </span>
                </span>
              </div>
               <div class="meeting-date">
               <i class="icon_img far fa-calendar-alt"></i>
                <span >
                  {{ meeting.start_time | moment("Do MMMM  YYYY") }}
                </span>
              </div>
            </div>
            <div class="meeting-btns d-flex justify-content-between">
              <button
                v-if="!userData.roles.includes('viewer')"
                @click="$router.push(`/edit_meeting/${meeting.slug}`)"
                class="edit-btn"
               
              >
              <i class="icon_img fas fa-edit"></i>
                <span class="  ml-3">تعديل البيانات</span>
              </button>
              <button
                @click="$router.push(`view_meeting/${meeting.slug}`)"
                class="meeting-view"
              >
                <img src="/media/svg/copy.svg" alt="" />
                <span class=" ">مشاهدة بيانات الاجتماع</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <paginate
        v-if="totalPages > 1"
        v-model="page"
        :pageCount="totalPages"
        :page-range="15"
        :clickHandler="clickCallback"
        :prevText="'الصفحه السابقة'"
        :nextText="'الصفحه التالية'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>


<script>
import meetings from "../../../http/requests/meetings";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import truncate from "vue-truncate-collapsed";

export default {
  name: "MeetingsList",
  components: {
    truncate: truncate,
  },
  // components: { DatePicker },
  data() {
    return {
      search_word: "",
      inprogress_meeting: false,
      pending_meetings: false,
      complete_meetings: false,
      totalPages: 0,
      page: 1,
      meetingsList: [],
      popupActivo: false,
      userData: {},
    };
  },

  methods: {
    clickCallback(pageNum) {
      this.page = pageNum;
      this.getMeetings();
    },
    getMeetings() {
      this.meetingsList = [];
      this.$vs.loading();
      meetings
        .getMeetings(this.page, this.search_word)
        .then((response) => {
          this.totalPages = response.data.meta.last_page;
          this.meetingsList = response.data.data;
          this.$vs.loading.close();
        })
       .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    getMeetingsFilters(value) {
      this.meetingsList = [];
      this.$vs.loading();
      meetings
        .getMeetingsFilters(this.page, value)
        .then((response) => {
          this.totalPages = response.data.meta.last_page;
          this.meetingsList = response.data.data;
          this.$vs.loading.close();
        })
   .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    getfiltermeeting(value) {
      if (value == "inprogress_meeting") {
        (this.complete_meetings = false), (this.pending_meetings = false);
        if (this.inprogress_meeting) {
          this.getMeetingsFilters("inprogress");
        } else {
          this.getMeetings();
        }
      } else if (value == "pending_meetings") {
        (this.complete_meetings = false), (this.inprogress_meeting = false);
        if (this.pending_meetings) {
          this.getMeetingsFilters("pending");
        } else {
          this.getMeetings();
        }
      } else {
        (this.inprogress_meeting = false), (this.pending_meetings = false);
        if (this.complete_meetings) {
          this.getMeetingsFilters("complete");
        } else {
          this.getMeetings();
        }
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getMeetings();
  },
  created() {
 
    if (localStorage.getItem("id_token")) {
    this.getMeetings();
    this.userData = JSON.parse(localStorage.getItem("User_data"));
    }
  },
};
</script>
<style scoped lang="scss">
.btns {
   @media(max-width:992px){
     width: 100%;
  }

  .btn_Green {
    width: 100%;
    text-align: center;
  }
}
.meetings .input-search{
  max-width: 100%;
  input{
    width: 100%;
  }
}
</style>

